import { useCallback } from 'react';

import { RMButton } from '@/components/RMButton/RMButton';
import { useQueryParam } from '@/hooks/useQueryParam';
import { useNavbarProjectId, useNavbarStore } from '@/modules/navbar/states';
import { useServices } from '@/Services';
import { CollaborationInviteSourcePage } from '@/services/analytics/collaboration-analytics';

import { ProjectShareDialogContainer } from './ProjectShareDialog.container';

export interface ProjectShareButtonContainerProps {
  page: CollaborationInviteSourcePage;
}

export function ProjectShareButtonContainer({ page }: ProjectShareButtonContainerProps) {
  const { collaborationAnalyticsService } = useServices();

  const navbarStore = useNavbarStore();
  const projectId = useNavbarProjectId(navbarStore);

  const [shareDialogOpen, setShareDialogOpen] = useQueryParam('invite-dialog', 'boolean', false);

  const handleInvite = useCallback(() => {
    setShareDialogOpen(true);
    collaborationAnalyticsService.onInviteDialogOpened(page);
  }, [collaborationAnalyticsService, page, setShareDialogOpen]);

  return (
    <>
      <RMButton color="white" background="outlined" size="extra-small" onClick={handleInvite}>
        Invite
      </RMButton>

      <ProjectShareDialogContainer
        projectId={projectId || ''}
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(null)}
      />
    </>
  );
}
